  <template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="shower" scrollable persistent max-width="500px">
        <v-card>
          <v-card-title>{{ $t('sharefile.editsharefile') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap align-center justify-center>
              <v-flex xs12 lg12 ma-5>
                <v-card>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col cols="12">
                        <v-subheader>{{ $t('sharefile.sharewith') }}</v-subheader>
                        <v-layout row wrap align-center justify-center>
                          <v-flex lg11 xs11 >
                            <v-divider></v-divider>
                            <v-list flat>
                              <!-- <v-subheader>{{ $t('sharefile.sharewith') }}</v-subheader> -->
                              <!-- <v-list-item-group v-model="item" color="primary">
                                <v-list-item v-for="(item, i) in message" :key="i"> -->

                                  <v-layout row wrap justify-center>
                                    <v-list-item-group v-model="item" :color="color.theme">
                                    <v-list-item v-for="(item, i) in message" :key="i">
                                    <v-flex lg1 xs1>
                                      <v-list-item-icon>
                                        <v-icon :color="color.theme">mdi-email</v-icon>
                                      </v-list-item-icon> 
                                    </v-flex>
                                      <v-flex lg6 xs6>
                                      <v-list-item-content>
                                        <v-list-item-title v-text="item.business_email"></v-list-item-title>
                                      </v-list-item-content>
                                    </v-flex>
                                    <v-flex lg5 xs4>
                                      <v-list-item-action>
                                        <v-select
                                          v-model="statusshare[i]"
                                          :items="values"
                                          :label="$t('sharefile.Status')"
                                          outlined
                                          dense
                                          solo
                                          return-object
                                        >
                                          <template v-slot:item="{ item }">{{ $t(item.text) }}</template>
                                          <template v-slot:selection="{ item }">{{ $t(item.text) }}</template>
                                        </v-select>
                                      </v-list-item-action>
                                    </v-flex>
                                    <v-flex lg1 xs1 >
                                      <v-list-item-icon>
                                        <v-icon color="red" @click="deldata(item)">delete</v-icon>
                                      </v-list-item-icon>
                                    </v-flex>
                                    </v-list-item>
                                    </v-list-item-group>
                                  </v-layout>
                                <!-- </v-list-item>
                              </v-list-item-group> -->
                            </v-list>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              dark
              @click="$emit('closesharefile'),editapi()"
            >{{$t('sharefile.oksharefile') }}</v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('closesharefile'),closedialogedit()"
            >{{$t('uploadfolder.buttonclose') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  props: ["editdialog", "filedata"],
  computed: {
    ...mapState(["username", "authorize", "account_active",'color']),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.editdialog === true) {
          this.loaddata();
          this.$emit('closeDrag')
        }
        return this.editdialog;
      }
    }
  },
  data: () => ({
    item: '',
    values: [
      { name: "edit", key: "E", text: "sharefile.edit" },
      { name: "view", key: "V", text: "sharefile.view" }
    ],
    message: [],
    statusshare: []
  }),
  methods: {
   async loaddata() {
      this.message = [];
      if (this.filedata.file_type === "folder") {
        let getdata = {
          account_id_sender: this.dataAccountId,
          folder_id: this.filedata.file_id
        };
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FOLDER +
              "/api/get_sharewith_folder",
            getdata,{headers: { Authorization: auth.code },}
          )
          .then(response => {
            if (response.data.status === "OK") {
              this.message = response.data.message;
              for (let i = 0; i < this.message.length; i++) {
                let data = this.values.find(
                  v => v.key === this.message[i]["status"]
                );
                this.statusshare.push(data);
              }
            } else {
              console.log(response.data.errorMessage);
            }
          });
      } else {
        let getdata = {
          account_id_sender: this.dataAccountId,
          file_id: this.filedata.file_id
        };
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/get_sharewith_file",
            getdata
          )
          .then(response => {
            if (response.data.status === "OK") {
              this.message = response.data.message;
              for (let i = 0; i < this.message.length; i++) {
                let data = this.values.find(
                  v => v.key === this.message[i]["status"]
                );
                this.statusshare.push(data);
              }
            } else {
              console.log(response.data.errorMessage);
            }
          });
      }
    },
    closedialogedit() {
      this.message = [];
    },
    async editapi() {
      let dataarray = [];
      for (let i = 0; i < this.message.length; i++) {
        dataarray.push({
          child: this.message[i].account_id_reciever,
          status: this.statusshare[i].key
        });
      }
      console.log(dataarray);
      let payload = {
        id: this.filedata.file_id,
        account_id: this.dataAccountId,
        array: dataarray
      };
      if (this.filedata.file_type === "folder") {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
              "/api/update_status_byowner_folder",
            payload
          )
          .then(response => {
            if (response.data.status === "OK") {
              console.log(response.data);
            } else {
              console.log(response.data.errorMessage);
            }
          });
      } else {
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FILE +
              "/api/update_status_byowner_file",
            payload,{headers: { Authorization: auth.code },}
          )
          .then(response => {
            if (response.data.status === "OK") {
              console.log(response.data);
            } else {
              console.log(response.data.errorMessage);
            }
          });
      }
    },
   async deldata(item) {
      let datachild = [];
      datachild.push({ child: item.account_id_reciever });
      let payload = {
        id: this.filedata.file_id,
        account_id: this.dataAccountId,
        remove: datachild
      };
      if (this.filedata.file_type === "folder") {
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FOLDER +
              "/api/delete_sharing_folder",
            payload,{headers: { Authorization: auth.code },}
          )
          .then(response => {
            console.log(response.data);
            if (response.data.status === "OK") {
              this.loaddata();
              Toast.fire({
                icon: "warning",
                title: this.$t('sharefile.deletesuc')
              });
            }
          });
      } else {
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FILE +
              "/api/delete_sharing_file",
            payload,{headers: { Authorization: auth.code },}
          )
          .then(response => {
            console.log(response.data);
            if (response.data.status === "OK") {
              this.loaddata();
              Toast.fire({
                icon: "warning",
                title: this.$t('sharefile.deletesuc')
              });
            }
          });
      }
    }
  }
};
</script>